<template>
  <div class="up-requests">
    <h1 class="up-main-title">Мои заявки</h1>
    <div v-if="!loading" class="up-requests__items">
      <router-link
        v-for="r in requests"
        :to="`/customer/requests/${r.id}`"
        :key="r.id"
        class="up-requests__item"
      >
        <div class="up-requests__item-header">
          <p class="up-requests__item-id">{{ r.number }}</p>
          <time class="up-requests__item-created" :datetime="r.created">
            {{ r.created | ruDate("d.m.Y") }}
          </time>
        </div>
        <h2 class="up-requests__item-name">{{ r.course.name }}</h2>
        <p class="up-requests__item-listeners">
          Слушателей: {{ r.listeners ? r.listeners.length : 0 }}
        </p>
        <p class="up-requests__item-status">
          Статус: <span>{{ statusTranslated(r.orderStatus) }}</span>
        </p>
      </router-link>
    </div>
    <p v-if="!requests.length && !loading" class="up-empty-list-text">
      Список пуст
    </p>
    <el-pagination
      v-if="totalSize > perPage"
      background
      layout="prev, pager, next"
      :total="totalSize"
      :current-page.sync="currentPage"
      :page-size="perPage"
      @current-change="fetchRequests"
    />
  </div>
</template>

<script>
import requestStatuses from "@/enums/requestStatuses";

import { mapActions } from "vuex";

export default {
  name: "ClientRequests",
  data: () => ({
    requests: [],
    loading: true,
    perPage: 5,
    totalSize: 0,
    currentPage: 1,
  }),
  methods: {
    ...mapActions(["setNavigationText"]),
    statusTranslated(status) {
      const s = requestStatuses.find((s) => s.state === status);
      return s ? s.text : status;
    },
    async fetchRequests() {
      this.$nprogress.start();

      try {
        const { data: requests } = await this.$axios.get("/customer/orders", {
          params: {
            pageSize: this.perPage,
            pageNumber: this.currentPage - 1,
          },
        });

        this.requests = requests.items;
        this.totalSize = requests.totalSize;
      } catch (e) {
        //
      } finally {
        this.loading = false;
        this.$nprogress.done();
      }
    },
  },
  async created() {
    await this.fetchRequests();

    this.setNavigationText("Страница заявок");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/customer/requests/index.scss";
</style>